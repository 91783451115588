import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import media from "./media";

import HorizontalScroll from "./horizontal-scroll";
import SampleCards from "./samplecards";

const Main = styled.main``;

const HorizontalSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

const BumperSection = styled.section`
  text-align: center;
  padding: 128px 16px;
  background-color: #efefef;
`;

const CardsContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: top;
`;

const App = () => (
    <>
        <Main>
            {/*<BumperSection>*/}
            {/*    <div id="containerWidth" style={{maxWidth: 991 + 'px', margin: 'auto', background: 'red'}}>*/}
            {/*        <h2>Scroll down to reach the horizontal scroll section</h2>*/}
            {/*    </div>*/}
            {/*</BumperSection>*/}
            <HorizontalSection>
                <HorizontalScroll>
                    <CardsContainer>
                        <SampleCards/>
                    </CardsContainer>
                </HorizontalScroll>
            </HorizontalSection>
            {/*<BumperSection>*/}
            {/*    <h2>You have left the horizontal horizontal scroll section</h2>*/}
            {/*</BumperSection>*/}
        </Main>
    </>
);

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
