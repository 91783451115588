// import data from './test.json';
import React, {Component} from 'react';
import styled from "styled-components";
import media from "./media";

const SampleCard = styled.div`
  position: relative;npm start
  height: auto;
  width: calc(100vw - 10px);
  // background-color: #111f30;
  color: #000;
  margin-right: 30px;
  flex-shrink: 0;
  padding: 3rem 1.5rem 3rem 0;
  ${media.lg`
    width: 50vw;
    margin-right: 75px;
    padding: 3rem 3rem 3rem 0;
    
    &:last-of-type {
        margin-right: 75px;
        padding: 3rem 3rem 3rem 0;
    }
  `};
  
  &:last-of-type {
    margin-right: 0;
    padding: 3rem 0 3rem 1.5rem;
  }
`;

class SampleCards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            items: []
        };
    }

    componentDidMount() {
        console.log(document.documentElement.lang);
        fetch("https://kesslervogler.ch/wp-json/home-slider/v1/slides/" + ((document.documentElement.lang === 'en-US') ? '/?lang=en' : '/?lang=de'))
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        items: result.data
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }


    render() {
        const { error, items } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else {
            return (

                <>
                    {items.map((item, index) => (
                        <SampleCard key={index}>
                            <div>
                                <h2 className="mb-30">{item.content}</h2>
                                <a className="arrow-link border red d-flex d-sm-inline-flex justify-content-center justify-content-sm-start btn" href={item.link.url} target="_blank" rel="noopener noreferrer">
                                    <i className="icon-link"></i>
                                    <span>{item.link.title}</span>
                                </a>
                            </div>
                        </SampleCard>
                    ))}
                </>

            );

        }
    }
}

export default SampleCards